import * as React from "react";
import CallToAction from "../components/CallToAction/CallToAction";
import Layout from "../components/Layout/Layout";
import Companies from "../components/Companies/Companies";
import Masthead from "../components/Masthead/Masthead";
import Service from "../components/Service/Service";
import TextMedia from "../components/TextMedia/TextMedia";
import MastImage from "../images/industries/accountancy-header.jpg";
import Testimonial from "../components/Testimonial/Testimonial";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const quoteData = {
  text: "T Cards Online has revolutionised client process tracking and asset transfer management for me. I now know the total value of assets and advice sitting at each stage of the process. This helps me prioritise, forecast and assess the value of work in progress. The flexibility of the system means that I can continually make improvements and efficiency has really increased.",
  author:
    "James Boughton-Thomas, Practice Financial Planner, Tunstall Financial Management",
  image: <StaticImage src='../images/industries/tf-test.jpg' alt='' />,
};

const ctaData = [
  {
    text: "Having a visual plan that is easily accessible can seriously improve efficiency.",
  },
  {
    text: "Allocate tasks in a clear precise way. Highlight responsibilities and deadlines. Avoid duplication. Co-ordinate more effectively.",
  },
  {
    text: "See clearly where a task is in the process. Identify spare capacity and bottlenecks. Give accurate estimates of completion.",
  },
  {
    text: "Adjusting Boards or Card Designs is simple. Adapt to your workload and requirements.",
  },
];

const screensData = [
  {
    title: "Calendar or Custom Boards",
    text: "Customised T Boards to suit your processes. Identify task progress. Setup notifications and alerts for reminders. Simple to setup and edit. Create and name Row Groups to allocate to teams or individuals.",
    image: (
      <StaticImage
        src='../images/industries/accountancy/board.jpg'
        alt='Calendar or Custom Boards'
      />
    ),
  },
  {
    title: "Unlimited Card Designs",
    text: "Card Designs can easily be created to suit any process using one of our design templates or simply create your own. Simple to adapt and edit as your business changes and grows.",
    image: (
      <StaticImage
        src='../images/industries/accountancy/card.jpg'
        alt='Unlimited Card Designs'
      />
    ),
  },
  {
    title: "Flexible User Permissions",
    text: "Change permissions to restrict the access or editing rights for all team members. Choose who can edit or view anything from Boards to Row Groups to individual fields.",
    image: (
      <StaticImage
        src='../images/industries/accountancy/permissions.jpg'
        alt='Flexible User Permissions'
      />
    ),
  },
];

const Title = () => {
  return (
    <div className='mb-5'>
      <h2 className='title italic'>
        A simple way of increasing efficiency by having a clear plan of your
        workload.
      </h2>
      <p>
        T Card boards and cards specifically designed for financial services.
      </p>
      <p>
        Simple bespoke options for managing Staff to maximise resources and
        improve customer service with the ability to review, allocate and
        improve.
      </p>
    </div>
  );
};

const AccountancyPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>T Cards Online | Agile Financial Services & Accountancy</title>
      </Helmet>
      <Masthead image={MastImage}>
        <h1>Agile Financial Services & Accountancy - Visual Planning</h1>
      </Masthead>
      <CallToAction data={ctaData} sectionTitle={<Title />} />
      <Testimonial data={quoteData} />
      <TextMedia data={screensData} />

      <Service />
      <Companies title='Trusted across the industry' logos={data} />
    </Layout>
  );
};

export default AccountancyPage;

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/images/logos/accountancy/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(height: 115)
          }
        }
      }
    }
  }
`;
